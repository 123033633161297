<script setup lang="ts">
const {
  angle = 45,
  direction = 'cw',
  shadowColor = '#422006',
  lightColor = '#FFFD5A',
} = defineProps<{
  angle?: number
  direction?: 'cw' | 'ccw'
  shadowColor?: string
  lightColor?: string
}>()

const initialAngle = computed(() => `${angle}deg`)
const finalAngle = computed(() => direction === 'ccw' ? `${angle - 100}deg` : `${angle + 100}deg`)
</script>

<template>
  <div class="flex rounded-lg p-0.5 overflow-hidden linear-gradient ">
    <div class="rounded-lg overflow-hidden bg-neutral-900 w-full ">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@property --shadowColor {
  syntax: '<color>';
  inherits: false;
  initial-value: #404040;
}
@property --lightColor {
  syntax: '<color>';
  inherits: false;
  initial-value: #f5f5f5;
}

@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

.linear-gradient {
  --shadowColor:#171717;
  --lightColor:#262626;
  --a:v-bind(initialAngle);

  transition: --shadowColor 0.5s, --lightColor 0.5s, --a 0.5s;
  background: linear-gradient(var(--a), var(--lightColor),  var(--shadowColor),  #0a0a0a);

  &:hover {
    --a: v-bind(finalAngle);
    --shadowColor: v-bind(shadowColor);
    --lightColor: v-bind(lightColor);
  }
}
</style>
